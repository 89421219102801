@import '~antd/dist/antd.css';

.logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

// general
#root {
  height: 100%;
}
.content-holder {
  padding: 20px 24px;
  min-height: 100%;
  &.white {
    background: #fff;
  }
}

.button-action-bar {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1em;
  button {
    margin-left: .5em;
    &:first-of-type {
      margin-left: 5em;
    }
  }
}

// header
header {
  .ant-dropdown-link {
    color: rgba(0, 0, 0, 0.65);
    padding-left: 1em;
    padding-right: 1em;
    &.ant-dropdown-open {
      background: rgba(0,0,0,.025);
    }
  }
}

// login form
.login-container {
  display: flex;
  justify-content: center !important;
  height: 100% !important;
  align-items: center !important;
  background: #eee;
}
.login-form {
  max-width: 300px;
  .login-form-button {
    width: 100%;
  }
}
